import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as TWEEN from '@tweenjs/tween.js'


import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import vertex from './shaders/vertex.glsl'
import fragment from './shaders/fragment.glsl'
import vertex1 from './shaders/vertex1.glsl'
import fragment1 from './shaders/fragment1.glsl'

import atlas from './atlas.png'

import { DotScreenShader } from './shaders/CustomShader';
import { AnimationActionLoopStyles } from 'three';

/**
 * Base
 */
// Debug
// Canvas
const canvas = document.querySelector('canvas.bg')

// Scene
const scene = new THREE.Scene()

const cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256, {
    format: THREE.RGBAFormat,
    generateMipmaps: true,
    minFilter: THREE.LinearMipMapLinearFilter,
    encoding: THREE.sRGBEncoding
}
)

const cubeCamera = new THREE.CubeCamera(0.1, 10, cubeRenderTarget);

/**
 * First object
 */


// Geometry
const geometry = new THREE.SphereBufferGeometry(1.5, 32, 32)

// Material
const material = new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
    },
    side: THREE.DoubleSide,
    uniforms: {
        time: { value: 0 },
        resolution: { value: new THREE.Vector4() },
        uvRate1: {
            value: new THREE.Vector2(1, 1)
        }
    },
})

// Mesh
const mesh = new THREE.Mesh(geometry, material)
scene.add(mesh)

/**
 * Second object
 */

// Geometry
const geometry1 = new THREE.SphereGeometry(0.2, 56, 16);

// Material
const material1 = new THREE.ShaderMaterial({
    vertexShader: vertex1,
    fragmentShader: fragment1,
    extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
    },
    side: THREE.DoubleSide,
    uniforms: {
        time: { value: 0 },
        tCube: { value: 0 },
        resolution: { value: new THREE.Vector4() },
    },
    defines: {
        USE_TANGENT: ''
    }
})

// Mesh
const mesh1 = new THREE.Mesh(geometry1, material1)
mesh1.position.z = -.4;
scene.add(mesh1);


// Text


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    //Update composer
    composer.setSize(sizes.width, sizes.height);

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(55, sizes.width / sizes.height, 0.1, 100)
camera.position.set(1.2, 0.3, 1);
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


// Post Processing 

const composer = new EffectComposer(renderer);
composer.addPass(new RenderPass(scene, camera));

const effect1 = new ShaderPass(DotScreenShader);
effect1.uniforms['scale'].value = 4;
composer.addPass(effect1);

/**
 * Animate
 */

// Tweening

const finalX = 0.5;
const finalY = 0.7;
const finalZ = 0;

const cameraStart = camera.position;

const cameraEnd = { x: finalX, y: finalY, z: finalZ };

const tweenLoad = new TWEEN.Tween(cameraStart)
    .to(cameraEnd, 2500)
    .easing(TWEEN.Easing.Quadratic.Out)
    .start();


// Tween hover

let mouse = new THREE.Vector2();

window.addEventListener('mousemove', (event) => {

    mouse.x = event.clientX / sizes.width * 2 - 1;
    mouse.y = - (event.clientY / sizes.height) * 2 + 1;

})


const clock = new THREE.Clock();
let previousTime = 0;

const tick = () => {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;

    // Tweening

    TWEEN.update();

    const mouseX = mouse.x * 0.5;
    const mouseY = - mouse.y * 0.5;

    mesh.rotation.x += (mouseX - mesh.rotation.x) * 5 * deltaTime;
    mesh.rotation.y += (mouseY - mesh.rotation.y) * 5 * deltaTime;

    mesh1.position.x += 0.5 * (mouseX * 0.2 - mesh1.position.x) * deltaTime;
    mesh1.position.y += 0.5 * (mouseY * 0.2 - mesh1.position.y) * deltaTime;

    //Update shader uniforms
    material.uniforms.time.value = elapsedTime / 2;

    mesh1.visible = false;
    cubeCamera.update(renderer, scene);
    mesh1.visible = true;
    material1.uniforms.tCube.value = cubeRenderTarget.texture;


    // Update controls
    controls.update()

    // Render
    composer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}


tick();